import { createSlice } from "@reduxjs/toolkit";

export const communicationSlice = createSlice({
  name: "communication",
  initialState: { twilioDevice: "", messenger: null },
  reducers: {
    setTwilioDevice: (state, { payload }) => {
      state.twilioDevice = payload;
    },
    setMessenger: (state, { payload }) => {
      state.messenger = payload;
    },
    setCallInProgress: (state, { payload }) => {
      state.messenger = { ...state.messenger, callInProgress: payload };
    },
    setClientPhone: (state, { payload }) => {
      state.messenger = {
        ...state.messenger,
        clientPhone: payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTwilioDevice,
  setMessenger,
  setCallInProgress,
  setClientPhone,
} = communicationSlice.actions;

export default communicationSlice.reducer;
