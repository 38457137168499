import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
  name: "communication",
  initialState: { fetchNotes: false, caseNavigation: null },
  reducers: {
    setFetchNotes: (state, { payload }) => {
      state.fetchNotes = payload
    },
    setCaseNavigation: (state, { payload }) => {
      state.caseNavigation = payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setFetchNotes, setCaseNavigation } = globalSlice.actions

export default globalSlice.reducer