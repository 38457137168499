import { createSlice } from "@reduxjs/toolkit";

export const CaseFilterSlice = createSlice({
  name: "caseFilter",
  initialState: {
    // surplusAmount: "",
    caseProgress: "",
    caseStage: "",
    caseStatus: "",
  },
  reducers: {
    setCaseFilters: (state, action) => {
      state = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCaseFilters } = CaseFilterSlice.actions;

export default CaseFilterSlice.reducer;
