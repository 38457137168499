import { createSlice } from '@reduxjs/toolkit'

export const deleteModalSlice = createSlice({
  name: 'deleteModal',
  initialState: {
    isOpen: false,
    modalData: {
      title: '',
      message: '',
      type: ''
    },
    id: '',
    deleted: false
  },
  reducers: {
    openDeleteModal: (state, action) => {
      state.isOpen = action.payload
    },
    deleteModalData: (state, action) => {
      state.modalData = action.payload
    },
    deleteModalId: (state, action) => {
      state.id = action.payload
    },
    setDeleted: (state, action) => {
      state.deleted = !state.deleted
    },
  },
})

// Action creators are generated for each case reducer function
export const { openDeleteModal, deleteModalData, deleteModalId, setDeleted  } = deleteModalSlice.actions

export default deleteModalSlice.reducer